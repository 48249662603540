import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
// import Snowfall from "react-snowfall";
//ESTILOS
import "./App.css";
//PAGES
import Layout from "./pages/Layout";
import Loader from "./pages/Loader";
import Maintenance from "./pages/Maintenance";
//RUTAS
import AppRouter from "./routers";
import { BrowserRouter } from "react-router-dom";
//CONTEXT
import { useUsuario, UsuarioProvider } from "./context/usuario-context";
import { CartProvider } from "./context/cart-context";
import "moment/locale/es";
//FIREBASE
import { onSnapshot, query, doc } from "firebase/firestore";
import { db } from "./firebase";
//MEDIA
// import snowImg from "./assets/images/snow.png";

// const loadImage = (src) => {
//   return new Promise((resolve, reject) => {
//     const img = new Image();
//     img.src = src;
//     img.onload = () => resolve(img);
//     img.onerror = (error) => reject(error);
//   });
// };

const AppExport = () => (
  <UsuarioProvider>
    <CartProvider>
      <App />
    </CartProvider>
  </UsuarioProvider>
);

function App() {
  const { isLoadUser, isLogged } = useUsuario();
  const [configurations, setConfigurations] = useState([]);
  // const [snowflakes, setSnowflakes] = useState([]);

  useEffect(() => {
    //Datos de configuración
    const qConfig = query(doc(db, "config", "configurations"));

    const unlistenqConfig = onSnapshot(qConfig, (docs) => {
      let configF = docs.data();
      setConfigurations(configF);
    });

    // Cargar las imágenes de nieve de manera asíncrona
    // Promise.all([loadImage(snowImg)])
    //   .then((loadedImages) => {
    //     setSnowflakes(loadedImages);
    //   })
    //   .catch((error) => {
    //     console.error("Error al cargar las imágenes:", error);
    //   });

    return () => {
      unlistenqConfig();
    };
  }, []);

  if (isLoadUser() && configurations.length === 0) {
    return <Loader />;
  }

  return (
    <>
      {configurations.on ? (
        <>
          <BrowserRouter>
            <Layout configurations={configurations}>
              <AppRouter
                isLogged={isLogged}
                configurations={configurations}
              />
            </Layout>
          </BrowserRouter>
          {/*<Snowfall
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              pointerEvents: "none",
              zIndex: 9999,
            }}
            snowflakeCount={20}
            images={snowflakes}
            radius={[0.5, 20.0]}
          />*/}
        </>
      ) : (
        <Maintenance />
      )}

      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default AppExport;
